import {EditorSDK, ComponentRef, IGetDataByAppDef} from '@wix/platform-editor-sdk'

export type OpenSettingsParams = Parameters<EditorSDK['editor']['openComponentPanel']>[1]
export type ObligatorySettingsParams = {
  url: string
  componentRef: ComponentRef
}

const baseSettingsPanelOptions: Partial<OpenSettingsParams> = {
  width: 404,
  height: 583,
  type: 'settings',
}

export const openSettings = (
  editorSdk: EditorSDK,
  token: string,
  params: OpenSettingsParams & ObligatorySettingsParams,
  options?: {preloader?: boolean},
) => {
  const onOpen = panelToken => {
    if (options.preloader && panelToken) {
      editorSdk.editor.showPanelPreloader(panelToken)
    }
  }
  editorSdk.editor.openComponentPanel(
    token,
    {
      ...baseSettingsPanelOptions,
      ...params,
    },
    onOpen,
  )
}

type AppData = IGetDataByAppDef & {
  widgets?: {
    [key: string]: {settings?: {url?: string; urlV2?: string}}
  }
}

export const getSettingsUrl = (appData: AppData, appDefId: string) => {
  const widgetSettings = appData?.widgets?.[appDefId]?.settings

  if (widgetSettings) {
    return widgetSettings.urlV2 || widgetSettings.url
  }
}
